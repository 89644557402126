import React,{useState,useEffect} from 'react'
import { Link } from 'gatsby'
import tw from 'tailwind.macro'
import { css } from '@emotion/core'
import { ThemeProvider, themes, useTheme, withTheme } from './Theming'
import { bpMinSM, bpMinMD, bpMinLG, bpMinXL } from 'lib/breakpoints'
import Search from './Search'
import Container from './Container'
import Logo from './Logo'
import ThemeToggler from './ThemeToggler'
import Avatar from "../../assets/avatar.jpg"

const Header = ({ siteTitle , showImg, theme}) => {

  return (
    <header css={css(tw`w-full flex-shrink-0 bg-body`)}>
      <Container noVerticalPadding>
        <nav css={css(tw`w-full flex justify-between items-center`)}>
          <Link
            to="/"
            aria-label="go to homepage"
            activeClassName="active"
            css={css(
              {
                '@media (hover: hover)': {
                  ':hover': {
                    color: theme.colors.primary,
                  },
                },
              },
              tw`flex text-body-color`,
            )}
          >
            <Logo
              css={css({
                width: '80px',
                height: '66px',
                [bpMinSM]: {
                  width: '100px',
                  height: '82px',
                },
                [bpMinMD]: {
                  width: '120px',
                  height: '99px',
                },
                [bpMinLG]: {
                  width: '140px',
                  height: '115px',
                },
              })}
              baseColor={theme.colors.bodyColor}
              altColor={theme.colors.bodyBg}
            />
            <span
              css={css(
                {
                  display: 'flex!important',
                  margin: '20px 0 0 0px',
                  [bpMinSM]: {
                    margin: '20px 0 0 0px',
                  },
                  [bpMinMD]: {
                    margin: '30px 0 0 0px',
                  },
                  [bpMinLG]: {
                    margin: '40px 0 0 0px',
                  },
                },
                tw`block sm:block text-sm md:text-base lg:text-lg`,
              )}
              >
              {
                showImg === "False"
                ? <h3 
                    css={css(
                      {
                        color: theme.colors.primary,
                      }
                    )}
                  >  {siteTitle} </h3>
                :
                <> <img 
                      css={css(
                        {
                          marginRight: '0.875rem',
                          marginBottom: '0px',
                          marginTop: '20px',
                          width: '3rem',
                          height: '3rem',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          objectPosition: 'center center',
                          opacity: '1',
                          transition: 'opacity 500ms ease 0s',
                        }
                      )} src={Avatar}/> 
                      <h1 
                        css={css(
                        {
                          color: theme.colors.primary,
                        }
                        )}
                      > {siteTitle} </h1> </>
              }
              </span>

             
          </Link>
          <div
            css={{
              display: 'flex',
              marginTop: '20px',
              [bpMinSM]: {
                marginTop: '20px',
              },
              [bpMinMD]: {
                marginTop: '30px',
              },
              [bpMinLG]: {
                margin: '40px 0 0 0',
              },
            }}
          >
            <Search />

            <ThemeToggler
              css={{}}
              toggleTheme={theme.toggleTheme}
              themeName={theme.themeName}
            />
          </div>
        </nav>
      </Container>
    </header>
  )
}

export default withTheme(Header)
