const colors = {
  black: '#282c35',
  white: '#fff',
  pink: '#66E0FF',
  darkpink: '#007acc',
  //pink: '#d23669',
  //darkpink:'#00a023';
  fontblack: '#222',
  gray: '#dedede',
  red: '#E74C3C',
  violet: '#7F00FF',
}

// const colors = {
//   black: '#282c35',
//   white: '#fff',
//   pink: '#ffa7c4',
//   darkpink: '#d23669',
//   //pink: '#d23669',
//   //darkpink:'#00a023';
//   fontblack: '#222',
//   gray: '#dedede',
//   red: '#E74C3C',
//   violet: '#7F00FF',
// }

export default colors
