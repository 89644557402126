module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/blog your pathPrefix should be "blog"
  siteTitle: "Pallavi Bharadwaj", // Navigation and Site Title
  siteTitleAlt: 'Pallavi Bharadwaj Data Scientist', // Alternative Site title for SEO
  siteTitleShort: 'personal Blog by Sibi', // short_name for manifest
  siteUrl: 'https://www.pallavibharadwaj.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: 'https://pallavibharadwaj.com/images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription:
    'The personal blog by Pallavi Bharadwaj. I write articles related to computer science and programming.',
  author: {
    name: 'Pallavi Bharadwaj',
    image: 'https://www.pallavibharadwaj.dev/images/avatar.jpg',
    minibio: `
      <strong> Pallavi Bharadwaj </strong> is a Data Scientist based out of Vancouver, Canada.
    `,
  }, // Author for schemaORGJSONLD
  organization: '',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@pallavibharadwj', // Twitter Username
  ogSiteName: 'Pallavi Bharadwaj - Data Scientist', // Facebook Site Name
  ogLanguage: 'en_US',

  // Manifest and Progress color
  themeColor: '#5348FF',
  backgroundColor: '#2b2e3c',

  // Social component
  twitter: 'https://twitter.com/pallavibharadwj',
  twitterHandle: '@pallavibharadwj',
  github: 'https://github.com/pallavibharadwaj',
  linkedin: 'https://in.linkedin.com/in/pallavirbharadwaj',
}
